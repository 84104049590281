import React from "react";
import "./TimeframeContainer.scss";
import ChevronButton from "../../../../components/Buttons/ChevronBtn";
import Step from "../step/Step";

const TimeframeContainer = ({
  currentWidth,
  title,
  stepsData,
  isRotated,
  wrapperStyles,
  triangleStyles,
  cellWidth,
  stepsContainerStyles = null,
  clippedStep = false,
}) => {
  return (
    <div className="wrapper" style={{ ...wrapperStyles }}>
      <div className="chevrone-btn-container">
        <div className="chevrone-btn-container__title">{title}</div>
        <div className="chevrone-btn-wrapper">
          <ChevronButton isRotated={isRotated} />
        </div>
      </div>
      <div
        className="steps-block"
        style={{
          ...stepsContainerStyles,
          gap: currentWidth >= 900 && clippedStep ? "10px" : "0",
          paddingBottom: currentWidth < 900 && clippedStep ? "15px" : "",
        }}
      >
        {stepsData.map((step, idx) => {
          return (
            <div style={{ width: "100%", zIndex: `${10 - idx}` }} key={idx}>
              <Step
                currentWidth={currentWidth}
                key={idx}
                index={idx}
                styles={{
                  ...step.styles,
                  width:
                    (clippedStep && idx !== 0) || currentWidth < 900
                      ? `calc(${cellWidth} * ${step.duration_weeks}px)`
                      : `calc(${cellWidth} * ${step.duration_weeks}px - 20px)`,

                  // width:
                  // currentWidth < 900
                  //   ? `calc(${cellWidth} * ${step.duration_weeks}px - 0px)`
                  //   : `calc(${cellWidth} * ${step.duration_weeks}px - 10px)`,
                }}
                title={step.title}
                position={step.position ? step.position : null}
                clippedStep={clippedStep}
              />
            </div>
          );
        })}
      </div>

      <div className="arrow-triangle" style={triangleStyles}></div>
    </div>
  );
};

export default TimeframeContainer;
