import React from "react";
import "./Step.scss";

const Step = ({
  currentWidth,
  index,
  styles,
  position,
  title,
  clippedStep = false,
}) => {
  return (
    <div
      className="step-arrow-container"
      style={{
        justifyContent: position,
        zIndex: `${10 - index}`,
        height: currentWidth < 900 && clippedStep ? "100%" : "",
      }}
    >
      <div
        className="step"
        style={{
          ...styles,
          height: currentWidth < 900 && clippedStep ? "100%" : "",
          marginLeft: "0",
          //  marginLeft: currentWidth > 900 && clippedStep && index > 0 ? "10px" : "0",
        }}
      >
        <div
          className="step-title"
          style={{
            marginLeft:
              currentWidth < 900 && !clippedStep
                ? "5px"
                : currentWidth < 900 && clippedStep
                ? "0"
                : "30px",
            rotate: currentWidth < 900 && clippedStep ? "90deg" : "",
          }}
        >
          {title}
        </div>
        {clippedStep && currentWidth < 900 && index !== 3 ? (
          <div></div>
        ) : clippedStep && currentWidth < 900 && index == 3 ? (
          <div
            className="clipped-box-triangle"
            style={{ backgroundColor: styles.backgroundColor }}
          ></div>
        ) : (
          <div
            className="step-triangle"
            style={{ backgroundColor: styles.backgroundColor }}
          ></div>
        )}

        {clippedStep && index !== 0 && currentWidth >= 900 ? (
          <div
            className="step-triangle"
            style={{
              backgroundColor: "#f7fcff",
              left: "-15px",
              border: "unset",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Step;
