import React, { useEffect, useState } from "react";
import "./TimeframeTable.scss";
import { getLink } from "../../../../services/utils";
import TimeframeContainer from "../timeframe_container/TimeframeContainer";

const TimeframeTable = ({ currentWidth }) => {
  const steps_data = {
    plan: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Onboarding",
        duration_weeks: 3,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
        },
        title: "Business analysis",
        duration_weeks: 4,
      },
      {
        styles: {
          backgroundColor: "#438474",
        },
        title: "Design",
        duration_weeks: 3,
        position: "flex-end",
      },
    ],
    develop: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Develop",
        duration_weeks: 2,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
          marginLeft: "10px",
        },
        title: "Review",
        duration_weeks: 1,
      },
      {
        styles: {
          backgroundColor: "#438474",
          marginLeft: "10px",
        },
        title: "Deploy",
        duration_weeks: 1,
      },
      {
        styles: {
          backgroundColor: "#6F4D63",
          marginLeft: "10px",
        },
        title: "Test",
        duration_weeks: 1,
      },
    ],
    scale: [
      {
        styles: {
          backgroundColor: "#0071CE",
        },
        title: "Train",
        duration_weeks: 1.5,
      },
      {
        styles: {
          backgroundColor: "#0A1E8E",
        },
        title: "Assess",
        duration_weeks: 2,
      },
      {
        styles: {
          backgroundColor: "#438474",
        },
        title: "Maintain",
        duration_weeks: 2.5,
      },
    ],
  };

  const [cellWidth, setCelltWidth] = useState(0);
  const [isRotated, setRotate] = useState({
    plan: false,
    develop: false,
    scale: false,
  });
  const [description, setDescription] = useState("");

  useEffect(() => {
    let descriptionBlock = document.getElementById("description");
    let translateTo = document.getElementById("table-cell").offsetHeight;
    let timeframeContainer = document.getElementById("timeframe-container");

    let tableHeight = document.getElementById("table").offsetHeight;

    if (Object.values(isRotated).every((el) => el === false)) {
      timeframeContainer.style.height = "100vh";

      let blocksToShow = document.querySelectorAll(
        Object.keys(isRotated).map((key) => `#${key}`)
      );

      blocksToShow.forEach((b) => {
        b.style = { ...b, transition: "opacity: 0.3s", opacity: "1" };
      });

      descriptionBlock.style.transition = "opacity 0.3s";
      descriptionBlock.style.opacity = "0";
      setTimeout(() => {
        descriptionBlock.style.display = "none";
      }, 300);
    } else {
      descriptionBlock.style.display = "block";
      descriptionBlock.style.transition = "opacity 0.3s";
      setTimeout(() => {
        descriptionBlock.style.opacity = "1";
      }, 100);

      let blocksToHide = Object.keys(isRotated)
        .filter((el) => !isRotated[el])
        .map((key) => `#${key}`);

      let container = document.querySelectorAll(blocksToHide);

      container.forEach((b) => {
        b.style.opacity = "0";
        b.style.display = "none";
      });

      let rotatedBlock =
        Object.keys(isRotated)[Object.values(isRotated).indexOf(true)];

      let imgContainerHeight =
        document.getElementById("image-container").offsetHeight;

      timeframeContainer.style.height = `calc( ${"100vh"}  + ${
        imgContainerHeight -
        translateTo -
        document.getElementById("table-header").offsetHeight -
        (currentWidth > 900 ? 10 : 76)
      }px)`;

      if (rotatedBlock !== "plan") {
        let findedBlock = document.getElementById(rotatedBlock);
        if (findedBlock) {
          findedBlock.style.transform =
            rotatedBlock === "develop"
              ? `translateY(-${translateTo}px)`
              : `translateY(-${translateTo * 2 - 20}px)`;
        }
      }

      let table = document.getElementById("table");
      table.style.height = `${tableHeight}px`;
    }
  }, [isRotated]);

  const changeRotate = (uniqueKey) => {
    setDescription(uniqueKey);

    setRotate({ ...isRotated, [uniqueKey]: !isRotated[uniqueKey] });
  };

  const onResize = () => {
    let cellWidth = document.getElementById("table-cell").offsetWidth;
    setCelltWidth(cellWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <div className="table-wrapper">
      <table id="table">
        <thead id="table-header">
          <tr>
            <th colSpan={3} style={{ width: "21.42%" }}>
              Initiation phase
            </th>
            <th colSpan={3} style={{ width: "21.42%" }}>
              {Object.values(isRotated).some((el) => el) ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Sprint 1</div>
                  <div className="header-sub-title">(3 weeks)</div>
                </div>
              ) : (
                "Design phase"
              )}
            </th>
            <th colSpan={5} style={{ width: "35.74%" }}>
              {Object.values(isRotated).some((el) => el) ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Sprint 2</div>
                  <div className="header-sub-title">(5 weeks)</div>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Development phase</div>
                  <div className="header-sub-title">
                    (sprint might be repeated)
                  </div>
                </div>
              )}
            </th>
            <th colSpan={3} style={{ width: "21.42%" }}>
              Wrapping phase
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="table-cell">
              <div
                id="plan"
                className="arrow-container"
                onClick={() => {
                  changeRotate("plan");
                }}
              >
                <TimeframeContainer
                  currentWidth={currentWidth}
                  isRotated={isRotated.plan}
                  wrapperStyles={{
                    height: "166px",
                    border: "2px dashed #0071ce",
                    backgroundColor: "#f7fcff",
                    width: `calc(${cellWidth} * 5.8px)`,
                  }}
                  title={"Plan"}
                  triangleStyles={{
                    width: " 115px",
                    height: "115px",
                    right: "-58px",
                    top: "24.5px",
                  }}
                  cellWidth={cellWidth}
                  stepsData={steps_data.plan}
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div
                id="develop"
                className="arrow-container"
                onClick={() => {
                  changeRotate("develop");
                }}
              >
                <TimeframeContainer
                  currentWidth={currentWidth}
                  isRotated={isRotated.develop}
                  wrapperStyles={{
                    height: currentWidth < 900 ? "124px" : "73px",
                    border: "2px dashed #0071ce",
                    backgroundColor: "#f7fcff",
                    width:
                      currentWidth > 900
                        ? `calc(${cellWidth} * 5px + 10px)`
                        : `calc(${cellWidth} * 5px - 5px)`,
                    // width: `calc(${cellWidth} * 4.95px + ${
                    //   currentWidth > 900 ? "10" : "0"
                    // }px`,
                  }}
                  stepsContainerStyles={{ flexDirection: "row" }}
                  title={"Develop"}
                  triangleStyles={
                    currentWidth < 900
                      ? {
                          width: "86px",
                          height: "86px",
                          right: "-44px",
                          top: "18px",
                        }
                      : {
                          width: " 49px",
                          height: "49px",
                          right: "-26px",
                          top: "11px",
                        }
                  }
                  cellWidth={cellWidth}
                  stepsData={steps_data.develop}
                  clippedStep={"true"}
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div
                id="scale"
                className="arrow-container arrow-container__scale"
                onClick={() => {
                  changeRotate("scale");
                }}
              >
                <TimeframeContainer
                  currentWidth={currentWidth}
                  isRotated={isRotated.scale}
                  wrapperStyles={{
                    height: "165px",
                    border: "2px dashed #0071ce",
                    backgroundColor: "#f7fcff",
                    width:
                      currentWidth > 600
                        ? `calc(${cellWidth} * 2.5px)`
                        : `calc(${cellWidth} * 2.5px + 20px)`,
                  }}
                  title={"Scale"}
                  triangleStyles={{
                    width: " 114px",
                    height: "114px",
                    right: "-58px",
                    top: "25px",
                  }}
                  cellWidth={cellWidth}
                  stepsData={steps_data.scale}
                />
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div id="description" className="description">
        <div id="image-container" className="image-container">
          <img
            style={{
              display:
                currentWidth >= 900 && description === "plan"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_plan_description_desktop`)}
            alt="plan description"
          />
          <img
            style={{
              display:
                currentWidth >= 600 &&
                currentWidth < 900 &&
                description === "plan"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_plan_description_tablet`)}
            alt="plan description"
          />
          <img
            style={{
              display:
                currentWidth < 600 && description === "plan" ? "block" : "none",
            }}
            src={getLink(`timeframe_plan_description_mobile`)}
            alt="plan description"
          />

          <img
            style={{
              display:
                currentWidth >= 900 && description === "develop"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_develop_description_desktop`)}
            alt="develop description"
          />

          <img
            style={{
              display:
                currentWidth >= 600 &&
                currentWidth < 900 &&
                description === "develop"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_develop_description_tablet`)}
            alt="develop description"
          />
          <img
            style={{
              display:
                currentWidth < 600 && description === "develop"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_develop_description_mobile`)}
            alt="develop description"
          />
          <img
            style={{
              display:
                currentWidth >= 900 && description === "scale"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_scale_description_desktop`)}
            alt="scale description"
          />
          <img
            style={{
              display:
                currentWidth >= 600 &&
                currentWidth < 900 &&
                description === "scale"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_scale_description_tablet`)}
            alt="scale description"
          />
          <img
            style={{
              display:
                currentWidth < 600 && description === "scale"
                  ? "block"
                  : "none",
            }}
            src={getLink(`timeframe_scale_description_mobile`)}
            alt="scale description"
          />
        </div>
      </div>
    </div>
  );
};

export default TimeframeTable;
