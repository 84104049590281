import React from "react";
import "./TeamMember.scss";

const TeamMember = ({ data }) => {
  return (
    <div className="member-container">
      <div className="member-container-avatar">
        <img src={data.icon} />
      </div>
      <div className="member-container-info">
        <div className="title-container">
          <div className="title">{data.title}</div>
          <div className="description-box">
            <div className="description">{data.description} </div>
            {data.grade ? (
              <div className="grade">
                <div className="dot"></div> {data.grade}
              </div>
            ) : null}
          </div>
        </div>
        <div className="skills-container">
          {data.skills.map((el, idx) => {
            return (
              <div style={{ width: "49px", height: "49px" }}>
                <img
                  src={el.icon}
                  alt={`${el.title} icon`}
                  title={el.title}
                  key={idx}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
