import React, { useEffect, useState } from "react";
import "./HowWeWork.scss";

import TimeframeTable from "./components/timeframe_table/TimeframeTable";
import TeamMember from "./components/team_member/TeamMember";
import { OurTeams } from "../../data/HowWeWork";

// import Platforms from "../../components/Platforms";
// import Works from "../../components/Works";
// import { SchemaDevice } from "../../data/HowWeWork";
// import ArrowUp from "../../assets/home/arrow_blue_up.svg";
// import Timeframe from "./components/Timeframe";

import { getLink } from "../../services/utils";

const HowWeWork = () => {
  // const [device, setDevice] = useState(null);
  // const [isVisible, setIsVisible] = useState(false);

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const onResize = () => {
    setCurrentWidth(window.innerWidth);
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // const handleScroll = () => {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //   setIsVisible(scrollTop > 500);
  // };

  // const onResize = () => {
  //   if (window.innerWidth <= 600) setDevice("mobile");
  //   else if (window.innerWidth > 600 && window.innerWidth <= 900)
  //     setDevice("tablet");
  //   else if (window.innerWidth > 900) setDevice("desktop");
  // };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    // window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      // window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const btns = [
  //   {
  //     key: "registration",
  //     class: {
  //       desktop: "registration-btn",
  //       tablet: "registration-btn-tablet",
  //       mobile: "registration-btn-mobile",
  //     },
  //   },
  //   {
  //     key: "customer-request",
  //     class: {
  //       desktop: "customer-request-btn",
  //       tablet: "customer-request-btn-tablet",
  //       mobile: "customer-request-btn-mobile",
  //     },
  //   },
  //   {
  //     key: "claims-portal",
  //     class: {
  //       desktop: "claims-portal-btn",
  //       tablet: "claims-portal-btn-tablet",
  //       mobile: "claims-portal-btn-mobile",
  //     },
  //   },
  //   {
  //     key: "claims-report",
  //     class: {
  //       desktop: "claims-report-btn",
  //       tablet: "claims-report-btn-tablet",
  //       mobile: "claims-report-btn-mobile",
  //     },
  //   },
  // ];

  return (
    <div>
      {/* <Timeframe /> */}
      <div className="hww-container">
        <div className="iceberg-container">
          <div className="iceberg-container-main">
            <div className="iceberg-container-title">
              <h3>Full stack application</h3>
              <div className="iceberg-container-title__sub">Iceberg view</div>
            </div>
            <div className="image-container">
              <img
                src={
                  currentWidth >= 900
                    ? getLink("hww_fullstack_iceberg_desktop")
                    : currentWidth >= 600
                    ? getLink("hww_fullstack_iceberg_tablet")
                    : getLink("hww_fullstack_iceberg_mobile")
                }
                alt="full-stack-iceberg-img"
              />
            </div>
          </div>
        </div>
        <div className="fullstack-container">
          <div className="fullstack-container-main">
            <div className="fullstack-container-title">
              <h3>Example of full stack application in real life</h3>
              <div className="square-container">
                <div className="square-box">
                  <div
                    className="square"
                    style={{ backgroundColor: "#0071CE" }}
                  ></div>
                  <div className="square-box__text">External applications</div>
                </div>
                <div className="square-box">
                  <div
                    className="square"
                    style={{ backgroundColor: "#171C8F" }}
                  ></div>
                  <div className="square-box__text">Full stack services</div>
                </div>
              </div>
            </div>

            <div className="image-container">
              <img
                src={
                  currentWidth >= 900
                    ? getLink("hww_fullstack_scheme_desktop")
                    : currentWidth >= 600
                    ? getLink("hww_fullstack_scheme_tablet")
                    : getLink("hww_fullstack_scheme_mobile")
                }
                alt="full-stack-iceberg-img"
              />
            </div>
          </div>

          {/* {btns.map((btn, idx) => (
            <button
              key={idx}
              className={
                currentWidth >= 900
                  ? btn.class.desktop
                  : currentWidth >= 600
                  ? btn.class.tablet
                  : btn.class.mobile
              }
            >
              -
            </button>
          ))} */}
        </div>
        <div id="timeframe-container" className="timeframe-container">
          <div className="timeframe-container-main">
            <div className="timeframe-container-title">
              <h3>Project timeframe</h3>
            </div>
            <div className="table-container">
              <TimeframeTable currentWidth={currentWidth} />
            </div>
          </div>
        </div>

        <div className="team-container">
          <div className="team-container-main">
            <div className="team-container-title">
              <h3>Our team</h3>
              <div className="sub-title">
                Meet some of the team members who contribute to our clients'
                success every day.
              </div>
            </div>

            {/* <div className="team-members">
              <div style={{ display: "flex", flexDirection: "row" }}>
                {OurTeams.slice(0, 3).map((el, idx) => (
                  <TeamMember data={el} key={idx} />
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {OurTeams.slice(3).map((el, idx) => (
                  <TeamMember data={el} key={idx} />
                ))}
              </div>
            </div> */}

            <div className="team-members">
              {OurTeams.map((el, idx) => (
                <TeamMember data={el} key={idx} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="hww-main">
        <Platforms />
        <div className="hww-container">
          <Works title="Project timeframe" noButton={true} />
          <h2>Software process overview</h2>
          <img
            src={SchemaDevice[device]}
            alt="Software process overview schema"
          />
        </div>
      </div>
      {isVisible && (
        <div className="button-to-start">
          <img src={ArrowUp} alt="ArrowUp" onClick={scrollToTop} />
        </div>
      )} */}
    </div>
  );
};

export default HowWeWork;
