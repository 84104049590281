// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-arrow-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  z-index: 2;
}
.step-arrow-container .step {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  font-family: var(--font-halyard);
  font-weight: 500;
  z-index: 1;
  color: #ffffff;
}
.step-arrow-container .step-title {
  transition: 0.3s;
  font-size: 12px;
  z-index: 5;
  margin-left: 30px;
}
@media screen and (min-width: 900px) {
  .step-arrow-container .step-title {
    font-size: 12px;
  }
}
@media screen and (min-width: 1152px) {
  .step-arrow-container .step-title {
    font-size: 14px;
  }
}
@media screen and (min-width: 1440px) {
  .step-arrow-container .step-title {
    font-size: 18px;
  }
}
.step-arrow-container .step .step-triangle {
  position: absolute;
  transform: rotate(45deg);
  width: 28px;
  height: 28px;
  right: -15px;
  top: 6px;
}

.clipped-box-triangle {
  position: absolute;
  transform: rotate(45deg);
  width: 60.4px;
  height: 60.4px;
  right: -30px;
  top: 12.5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/HowWeWork/components/step/Step.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,WAAA;EACA,UAAA;AACF;AACE;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,gBAAA;EACA,UAAA;EAsBA,cAAA;AApBJ;AAAI;EACE,gBAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AAEN;AAAM;EANF;IAOI,eAAA;EAGN;AACF;AADM;EAVF;IAWI,eAAA;EAIN;AACF;AAFM;EAdF;IAeI,eAAA;EAKN;AACF;AACI;EACE,kBAAA;EACA,wBAAA;EACM,WAAA;EACN,YAAA;EACA,YAAA;EACA,QAAA;AACN;;AAKA;EACE,kBAAA;EACA,wBAAA;EACA,aAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AAFF","sourcesContent":[".step-arrow-container {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  z-index: 2;\n\n  .step {\n    display: inline-flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    height: 40px;\n    font-family: var(--font-halyard);\n    font-weight: 500;\n    z-index: 1;\n\n    &-title {\n      transition: 0.3s;\n      font-size: 12px;\n      z-index: 5;\n      margin-left: 30px;\n\n      @media screen and (min-width: 900px) {\n        font-size: 12px;\n      }\n\n      @media screen and (min-width: 1152px) {\n        font-size: 14px;\n      }\n\n      @media screen and (min-width: 1440px) {\n        font-size: 18px;\n      }\n\n    }\n\n    color: #ffffff;\n\n    .step-triangle {\n      position: absolute;\n      transform: rotate(45deg);\n            width: 28px;\n      height: 28px;\n      right: -15px;\n      top: 6px;\n    }\n\n\n  }\n}\n.clipped-box-triangle {\n  position: absolute;\n  transform: rotate(45deg);\n  width: 60.4px;\n  height: 60.4px;\n  right: -30px;\n  top: 12.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
